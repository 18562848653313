"use client";
import { Item } from "types/Item";
import ScanCounts from "./api/ScanCounts";

export default class ScanCountService {
	public static readonly instance = new this();

	private constructor() {}

	public async update(item: Item) {
		await ScanCounts.getInstance().add(item.sku, item.nom_produit_fr);
	}
}
