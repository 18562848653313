import { BoundingBox } from "@mediapipe/tasks-vision";

import { useDebounce, useOrientation, useWindowSize } from "@uidotdev/usehooks";
import Typography, { ITypo, ITypoColor } from "components/design_system/Typography";
import React, { useCallback, useEffect, useRef, useState } from "react";
import HistoryService from "services/HistoryService";
import I18nStore from "stores/I18nStore";
import classes from "./classes.module.scss";
import ItemDrawerStore from "stores/ItemDrawerStore";
import { Item } from "types/Item";
import products from "../../assets/products_v4.json";
import ScanCountService from "services/ScanCountService";
import I18n from "components/materials/I18n";
export type IFacingModeType = "user" | "environment";

type IProps = {
	facingMode: IFacingModeType;
	onDetect: (points: Point[]) => void;
	setProgress: React.Dispatch<React.SetStateAction<number>>;
	onReady: React.Dispatch<React.SetStateAction<boolean>>;
	isPaused: boolean;
};

type IVideoSize = {
	width: number;
	height: number;
};

export type Point = {
	id: number;
	boundingBox: BoundingBox;
	name: string;
	frameLastSeen: number;
	x: number;
	y: number;
	score: number;
};

const isIPhone = navigator.userAgent.match(/iPhone/i);
const isAndroid = navigator.userAgent.match(/Android/i);
const isMobile = isIPhone || isAndroid;

function WebcamAi({ facingMode = "environment", onDetect, setProgress, onReady, isPaused }: IProps) {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const size = useWindowSize();
	const videoRef = useRef<HTMLVideoElement>(null);

	const [videoSize] = useState<IVideoSize>({ width: size.width ?? 0, height: size.height ?? 0 });
	const debouncedResize = useDebounce(size.width && size.height ? size.width.toString().concat(size.height.toString()) : undefined, 300);
	const imageSrc = useRef<string | null>(null);
	const [showImage, setShowImage] = useState<boolean>(false);
	const { type: orientationType } = useOrientation();
	// const [showHeart, setShowHeart] = useState<boolean>(I18nStore.getInstance().lang === "en");

	const videoStream = useRef<MediaStream | null>(null);
	const [pictureBlurry, setPictureBlurry] = useState<boolean>(false);
	const [isAnimating, setIsAnimating] = useState(false);

	videoSize.width = size.width ?? 0;
	videoSize.height = size.height ?? 0;

	if (size.width ?? 0 < (size.height ?? 0)) {
		videoSize.width = size.height ?? 0;
		videoSize.height = size.width ?? 0;
	}

	if (orientationType === "landscape-primary" || orientationType === "landscape-secondary") {
		videoSize.width = size.width ?? 0;
		videoSize.height = size.height ?? 0;
	}

	// useEffect(
	// 	() =>
	// 		I18nStore.getInstance().onChange(() => {
	// 			setShowHeart(I18nStore.getInstance().lang === "en");
	// 		}),
	// 	[],
	// );

	const startStream = useCallback(async () => {
		if (!videoSize.height || !videoSize.width || !canvasRef.current) return;

		if (videoRef.current && debouncedResize) {
			enableCam(videoRef.current, facingMode, videoSize)
				.then((values) => {
					videoRef.current?.play();
					if (isMobile && values.height > values.width) {
						canvasRef.current!.height = values.width;
						canvasRef.current!.width = values.height;

						return;
					}

					canvasRef.current!.width = values.height;
					canvasRef.current!.height = values.width;
				})
				.catch((err) => console.info(err));
		}
	}, [debouncedResize, facingMode, videoSize]);

	function stopStream() {
		if (!videoStream.current) return;
		videoStream.current.getTracks().forEach((track) => track.stop());
		videoStream.current = null;

		if (videoRef.current) {
			videoRef.current.pause();
			videoRef.current.src = "";
		}
	}

	/**
	 * @description Ask for user's webcam
	 */
	async function enableCam(video: HTMLVideoElement, facingMode: IFacingModeType = "environment", videoSize: IVideoSize, reverse = false) {
		if (videoStream.current) {
			video.pause();
			video.src = "";
			videoStream.current.getTracks().forEach((track) => track.stop());
		}
		return new Promise<{ width: number; height: number; stream: MediaStream }>((resolve, reject) => {
			const aspectRatio = videoSize.width / videoSize.height;

			navigator.mediaDevices
				.getUserMedia({
					video: {
						facingMode,
						aspectRatio: {
							exact: aspectRatio,
						},
					},
					audio: false,
				})
				.then(function (stream) {
					videoStream.current = stream;
					const trackSettings = stream.getVideoTracks()[0].getSettings();
					const width = trackSettings.width;
					const height = trackSettings.height;

					if (!width || !height) return;
					video.srcObject = stream;
					const onLoadeddata = () => {
						video.removeEventListener("loadeddata", onLoadeddata);
						resolve({ width, height, stream });
					};
					video.addEventListener("loadeddata", onLoadeddata);
				})
				.catch((err) => reject(err));
		});
	}

	const closeImage = useCallback(() => {
		imageSrc.current = null;
		onDetect([]);
		setShowImage(false);
		setPictureBlurry(false);
	}, [onDetect]);

	const takePhoto = useCallback(() => {
		setIsAnimating(false);
		setTimeout(() => {
			setIsAnimating(true);
		}, 10); // Small delay to restart animation
		if (!videoRef.current || !canvasRef.current) return;

		if (showImage) {
			closeImage();
			return;
		}

		// Pause the video stream
		videoRef.current.pause();

		const context = canvasRef.current.getContext("2d")!;
		context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

		// Convert the canvas to a blob (image file)
		canvasRef.current.toBlob((blob) => {
			if (!blob) return;

			// Create a form data object to hold the file
			const formData = new FormData();
			formData.append("file", blob, "photo.png");

			// Send the image file to the backend /embeddings endpoint
			fetch("https://app-111f377c-4a8d-4baa-8fe4-de5cc1ebfa29.cleverapps.io/api/v1/embeddings?neighbors=1", {
				method: "POST", // Change to POST instead of GET to send the file
				body: formData,
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.nearest_neighbors && data.nearest_neighbors.length > 0) {
						const imageClass = data.nearest_neighbors[0].image_class; // Get the first neighbor (or handle multiple)
						console.log("imageClass", imageClass);

						const obj = products.find((product) => product.sku === imageClass) as Item;
						console.log("Nearest neighbor:", obj);

						ScanCountService.instance.update(obj);
						HistoryService.instance.add(obj, "scan");
						ItemDrawerStore.instance.openDrawerItem(obj.sku);
					}
					// After processing, you can either restart the video or keep the image shown
					videoRef.current!.play(); // Restart video if needed
				})
				.catch((error) => {
					console.error("Error fetching similar images:", error);
					// Optionally, you could restart the video here in case of an error as well
					videoRef.current!.play(); // Restart video on error
				});
		}, "image/png");
	}, [closeImage, showImage]);

	useEffect(() => {
		let resetTimeout: NodeJS.Timeout;

		if (isAnimating) {
			resetTimeout = setTimeout(() => {
				setIsAnimating(false); // Reset loader after 3 seconds (animation duration)
			}, 3000); // 3 seconds = the animation duration
		}

		// Cleanup the timeout to avoid memory leaks
		return () => {
			clearTimeout(resetTimeout);
		};
	}, [isAnimating]);

	/**
	 * @description Ask user to enable webcam at didMount
	 */
	useEffect(() => {
		startStream();

		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				startStream();
			} else {
				stopStream();
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			stopStream();
		};
	}, [videoSize, facingMode, debouncedResize, onReady, startStream]);

	useEffect(() => {
		const removeOnChange = ItemDrawerStore.instance.onDrawerOpenChange((isOpened) => {
			if (!isOpened) {
				closeImage();
			}
		});

		return () => {
			removeOnChange();
		};
	}, [closeImage]);

	return (
		<div className={classes["root"]} style={{ height: size.height?.toString().concat("px"), width: size.width?.toString().concat("px") }}>
			<video
				className={classes["video"]}
				ref={videoRef}
				width={size.width?.toString().concat("px")}
				height={size.height?.toString().concat("px")}
				// autoPlay
				playsInline></video>

			<div className={classes["take-photo-button-container"]}>
				{!isAnimating && (
					<Typography typo={ITypo.TEXT_MD_MEDIUM} color={ITypoColor.WHITE}>
						<I18n map={"pages.scan.take_picture"} />
					</Typography>
				)}
				{isAnimating && (
					<Typography typo={ITypo.TEXT_MD_MEDIUM} color={ITypoColor.WHITE}>
						<I18n map={"pages.scan.detection"} />
					</Typography>
				)}

				<button className={classes["take-photo-button"]} onClick={takePhoto}>
					<div className={classes["inner-take-photo-button"]}></div>
				</button>
			</div>
			<div className={classes["loader-container"]}>
				{/* <div className={classes["loader"]}>
					<div className={`${classes["circle"]} ${isAnimating ? classes["animate"] : ""}`}></div>
				</div> */}
				<svg className={classes["loader"]} viewBox="0 0 36 36">
					<path
						className={`${classes["circle"]} ${isAnimating ? classes["animate"] : ""}`}
						d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
					/>
				</svg>
			</div>

			{showImage && pictureBlurry && (
				<div className={classes["picture-blurry"]}>
					<Typography typo={ITypo.TEXT_MD_BOLD} color={ITypoColor.BLACK}>
						{I18nStore.getInstance().translate("pages.scan.picture_blurry")}
					</Typography>
				</div>
			)}

			<canvas ref={canvasRef} className={classes["canvas"]}></canvas>
			{showImage && <img src={imageSrc.current ?? ""} alt="" className={classes["image"]} ref={imageRef} />}
		</div>
	);
}

export default React.memo(WebcamAi);
