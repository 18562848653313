"use client";
import { Item } from "types/Item";
import AddedToCart from "./api/AddedToCartCounts";

export default class AddedToCartService {
	public static readonly instance = new this();

	private constructor() {}

	public async update(item: Item) {
		await AddedToCart.getInstance().add(item.sku, item.nom_produit_fr);
	}
}
