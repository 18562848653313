import BaseApiService from "../BaseApiService";

interface UpdateAddedToCartResponse {
	data: {
		sku: string;
		name: string;
	};
	meta: {};
}

export default class AddedToCartCounts extends BaseApiService {
	private static instance: AddedToCartCounts;
	private baseUrl: string;

	private constructor() {
		super();
		this.baseUrl = process.env["REACT_APP_API_URL"]!.concat("/api/added-to-cart-counts");
		AddedToCartCounts.instance = this;
	}

	public static getInstance() {
		if (!AddedToCartCounts.instance) return new this();
		return AddedToCartCounts.instance;
	}

	public async add(sku: string, name: string): Promise<UpdateAddedToCartResponse> {
		const path = this.baseUrl;
		try {
			return await this.postRequest(path, { data: { sku, name } }, { Authorization: `Bearer ${process.env["REACT_APP_API_KEY"]}` });
		} catch (err) {
			return this.onError(err);
		}
	}
}
